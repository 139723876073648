import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import { withIntl } from "../i18n"
import Html from '../components/Html'
import SEO from '../components/seo'

import GoogleMapReact from "google-map-react"

import "./contact.css"

const mapStyle = [{"elementType":"geometry","stylers":[{"color":"#212121"}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"elementType":"labels.text.stroke","stylers":[{"color":"#212121"}]},{"featureType":"administrative","elementType":"geometry","stylers":[{"color":"#757575"}]},{"featureType":"administrative.country","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]},{"featureType":"administrative.land_parcel","stylers":[{"visibility":"off"}]},{"featureType":"administrative.locality","elementType":"labels.text.fill","stylers":[{"color":"#bdbdbd"}]},{"featureType":"poi","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#181818"}]},{"featureType":"poi.park","elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"featureType":"poi.park","elementType":"labels.text.stroke","stylers":[{"color":"#1b1b1b"}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"color":"#2c2c2c"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#8a8a8a"}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#373737"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"color":"#3c3c3c"}]},{"featureType":"road.highway.controlled_access","elementType":"geometry","stylers":[{"color":"#4e4e4e"}]},{"featureType":"road.local","elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"featureType":"transit","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#000000"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#3d3d3d"}]}]

const ContactPage = ({ data, google }) => (
  <Layout>
    <SEO title={data.contentfulPage.title} />
    <div id="contact-split">
        { /* <GoogleMapReact
            bootstrapURLKeys={{ key: config.keys.googleMaps }}
            defaultCenter={config.location}
            defaultZoom={14}
            options={{ styles: [...mapStyle] }}
        >
            <img id="contact-marker" src={data.logo.childImageSharp.fixed.src} {...config.location} />
        </GoogleMapReact> */ }
        <div id="contact-details" className="text-right">
            <h1 className="uppercase">{ data.contentfulPage.title } </h1>
            <Html html={data.contentfulPage.content.childContentfulRichText.html}/>
        </div>
        <img id="image-split" src={data.contentfulPage.image.fluid.src} alt=""/>
    </div>
  </Layout>
)

export const query = graphql`
  query ($locale: String!) {
    logo: file(relativePath: { eq: "icon.png" }) {
        childImageSharp {
            fixed(width: 128, height: 128) {
                src
            }
        }
    }
    contentfulPage (node_locale: { eq: $locale }, name: { eq: "Contact" }) {
        name
        title
        content {
            childContentfulRichText {
                html
            }
        }
        image {
            fluid(maxHeight: 2160) {
                src
            }
        }
        node_locale
    }
  }
`

export default withIntl(ContactPage)
